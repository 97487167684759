import React from 'react'

import Consts from 'consts'
import colors from 'design-system/tokens/colors'
import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'

import { Icon } from 'design-system/components/Icon'
import IconButton from 'design-system/components/IconButton'

import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import type { Id } from 'react-toastify'
import { ToastContainer as BaseToastContainer, toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { localize } from 'shared/utils/languageUtils'
import fonts from 'design-system/tokens/fonts'
import styled from '@emotion/styled'

export type Type =
  | typeof Consts.NOTIFICATION_TYPES.INFO
  | typeof Consts.NOTIFICATION_TYPES.SUCCESS
  | typeof Consts.NOTIFICATION_TYPES.ERROR
  | typeof Consts.NOTIFICATION_TYPES.WARNING

const getColor = (type: Type) => {
  switch (type) {
    case Consts.NOTIFICATION_TYPES.ERROR:
      return colors.alerts.red
    case Consts.NOTIFICATION_TYPES.SUCCESS:
      return colors.alerts.green
    case Consts.NOTIFICATION_TYPES.WARNING:
      return colors.alerts.yellow
    case Consts.NOTIFICATION_TYPES.INFO:
    default:
      return colors.primary
  }
}

const zIndex = 999999999999999
const iconSize = 'xsmall'

const getIcon = (type: Type) => {
  const color = getColor(type)
  switch (type) {
    case Consts.NOTIFICATION_TYPES.ERROR:
      return <Icon color={color} icon={faExclamationCircle} iconSize={iconSize} />
    case Consts.NOTIFICATION_TYPES.SUCCESS:
      return <Icon color={color} icon={faCheckCircle} iconSize={iconSize} />
    case Consts.NOTIFICATION_TYPES.WARNING:
      return <Icon color={color} icon={faExclamationTriangle} iconSize={iconSize} />
    case Consts.NOTIFICATION_TYPES.INFO:
    default:
      return <Icon color={color} icon={faInfoCircle} iconSize={iconSize} />
  }
}

const getType = (type: Type) => {
  switch (type) {
    case Consts.NOTIFICATION_TYPES.ERROR:
      return 'error'
    case Consts.NOTIFICATION_TYPES.SUCCESS:
      return 'success'
    case Consts.NOTIFICATION_TYPES.WARNING:
      return 'warning'
    case Consts.NOTIFICATION_TYPES.INFO:
    default:
      return 'info'
  }
}

// DO NOT CALL THIS METHOD DIRECTLY, CALL THE WRAPPER FROM 'utilsLibrary/notificationUtils'
export const sendNotification = (
  template: string,
  type: Type = Consts.NOTIFICATION_TYPES.INFO,
  opts: Record<string, any> | undefined = undefined
) => {
  // localize or interpolate the message
  const localizedMessage = localize(template)

  if (localizedMessage !== '') {
    const toastId = toast.isActive(localizedMessage)
      ? `${localizedMessage} ${Math.random()}`
      : localizedMessage

    const id = toast(localizedMessage, {
      type: getType(type),
      icon: () => getIcon(type),
      progressStyle: {
        backgroundColor: getColor(type),
      },
      autoClose: type === Consts.NOTIFICATION_TYPES.ERROR ? false : 3000,
      toastId,
      ...opts,
    })

    return id
  }
}

// The ID is the localized message itself unless you pass a custom toastId in the opts, or the message is duplicated which adds a random number at the end, you can get it by storing the returned value from the sendNotification function
export const removeNotification = (id: Id) => {
  toast.dismiss(id)
}

const PROGRESS_BAR_HEIGHT = '2px'

const ToastContainer = styled(BaseToastContainer)`
  z-index: ${zIndex};
  .Toastify__progress-bar--wrp {
    height: ${PROGRESS_BAR_HEIGHT};
  }
`

const baseStyle = {
  alignSelf: 'center',
  height: 'fit-content',
  minHeight: '42px',
  width: '508px',
  marginTop: 0,
  marginBottom: 0,
  fontWeight: fontWeights.normal,
  fontSize: fontSizes.body,
  color: colors.black,
  paddingTop: 0,
  paddingBottom: 0,
  zIndex,
}

export const NotificationsProvider = () => (
  <ToastContainer
    bodyStyle={{
      ...baseStyle,
      marginBottom: PROGRESS_BAR_HEIGHT,
      fontFamily: fonts.body,
      paddingTop: '8px',
      paddingBottom: '8px',
    }}
    closeButton={(props) => (
      <IconButton
        color={colors.grays.l2}
        icon={faTimes}
        iconSize="medium"
        onClick={props.closeToast}
        style={{
          alignSelf: 'center',
          paddingBottom: PROGRESS_BAR_HEIGHT,
        }}
      />
    )}
    newestOnTop
    position="top-center"
    role="alertdialog"
    style={baseStyle}
    theme="light"
    toastStyle={{
      ...baseStyle,
      height: 'fit-content',
      marginBottom: '10px',
      minHeight: '44px', // This is needed to replace a min height set by the library on 64px that we don't want
    }}
    transition={Slide}
  />
)
